import {
  tdt, maharishi 
} from "./assets";

const StaticData = {
  getExperience: function () {
    return [
      {
        "title": "Senior Software Engineer | SAP Commerce Consultant",
        "contain": "Code88 Limited Company",
        "years": "May 2018 - July 2022",
        "type": "work",
        "mainBadges": [
          "Java - Reactjs - MySQL"
        ],
        "badges": [
          "REST API",
          "Java",
          "Microservices",
          "Redis",
          "CDN",
          "Elastic Search",
          "JavaScript",
          "Bootstrap",
          "Reactjs",
          "MySQL"
        ]
      },
      {
        "title": "Software Engineer",
        "contain": "TMA Solutions Company",
        "years": "Sep 2014 - May 2018",
        "type": "work",
        "mainBadges": [
          "Java - Reactjs - Sysbase/Oracle/PostgreSQL"
        ],
        "badges": [
          "REST API",
          "Java",
          "Microservices",
          "JavaScript",
          "Bootstrap",
          "Reactjs",
          "Oracle",
          "Sysbase",
          "PostgreSQL"
        ]
      }
    ];
  },

  getEducation: function () {
    return [
      {
        "title": "Maharishi International University",
        "contain": "1000 N 4th St, Fairfield, IA 52557",
        "years": "(In progress via distance education; expected completion June 2025)",
        "logo": maharishi,
        "link": "https://www.miu.edu/",
        "mainBadges": ["Master of Science in Computer Science"],
        "badges": [
          "Web Application Architecture", "Enterprise Architecture", "Algorithms",
          "Modern Programming Practices", "Modern Web Applications", "DBMS", "Modern Application Architect",
            "Cloud Computing"
        ]
      },
      {
        "title": "University of Sciences Ho Chi Minh City",
        "contain": "Ho Chi Minh City, Vietnam",
        "years": "2004 - 2008",
        "logo": "https://en.hcmus.edu.vn/wp-content/uploads/2023/08/Logo-E-1.png",
        "link": "https://en.hcmus.edu.vn/",
        "mainBadges": ["Bachelor's in Computer Science"],
        "badges": []
      }
    ]
  },

  getSkills: function () {
    return [
      { id: "JAVA_skill", content: "JAVA", porcentage: "90%", value: "90" },
      { id: "AWS_skill", content: "AWS", porcentage: "80%", value: "80" },
      { id: "HTML5_skill", content: "HTML5/CSS/SCSS/BootStrap", porcentage: "90%", value: "90" },
      { id: "FrontEnd_skill", content: "JavaScript/jQuery/ReactJS", porcentage: "90%", value: "90"},
      { id: "ReactJS_skill", content: "Redis/Kafka/RabbitMQ/Nginx", porcentage: "80%", value: "80"},
      { id: "DB_skill", content: "MySQL/PostgreSQL", porcentage: "80%", value: "80"}
    ]
  },

  getSkills1: function () {
    return [
      { id: "Microservices_skill", content: "Microservices", porcentage: "80%", value: "80" },
      { id: "HTML5_skill", content: "Docker/Kubernates", porcentage: "70%", value: "70" },
      { id: "DP_skill", content: "Design Patterns: MVC/Singleton/Factory..", porcentage: "80%", value: "80" },
      { id: "SDLC_skill", content: "Agile / Scrum, Waterfall", porcentage: "80%", value: "80"},
      { id: "BD_skill", content: "Spark, Hive, HBase", porcentage: "80%", value: "80"},
      { id: "Platforms_skill", content: "Windows/MacOS", porcentage: "90%", value: "90"}
    ]
  },

  getAboutMe: function () {
    return [
      {
        id: "first-p-about",
        content: "Highly skilled Senior Java Developer and SAP Cloud Consultant with more than 10 years of hands-on experience developing and deploying Java applications, and 10+ years certified as an SAP Commerce Consultant."
      },
      {
        id: "second-p-about",
        content: "Proficient in both back-end and front-end development using Agile and Scrum methodologies, leveraging Java, Spring, Microservices, AWS, RESTful API, and databases such as Oracle, MySQL, and PostgreSQL."
      },
      {
        id: "third-p-about",
        content: "Specialized in B2C, B2B, B2B2C, and Marketplace accelerators, with skills in diverse integration methods and system landscapes, offering comprehensive end-to-end advisory on solutions and architectures."
          
      },
      {
        id: "four-p-about",
        content: "Able to utilize CI/CD tools like CCv2, Azure Pipeline, and SonarQube for seamless on-premises and cloud deployments."
          
      }
    ]
  },

  getBlogs: function () {
    return [
      {
        organisation: "A Comprehensive Guide to Angular Observables",
        logo: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*hiMztBaitn3472vRelNsjA.png",
        link: "https://medium.com/@lquocnam/a-comprehensive-guide-to-angular-observables-bde5542346fc",
        positions: [
          {
            title: "A Comprehensive Guide to Angular Observables",
            duration: "In Angular, Observables are an essential part of reactive programming, a programming paradigm that emphasizes the propagation of change through streams of data. Observables allow developers to handle asynchronous data and events more streamlined and efficient than traditional techniques like callbacks or promises. Observables are a powerful tool for building complex applications that require real-time data updates, event handling, and more. In this article, we’ll explore the basics of Observables in Angular, including what they are, how they work, and some of their most common use cases. By the end of this article, you’ll have a solid understanding of how Observables can help you build better Angular applications.",
            content: [
              {
                text: "Read more",
                link: "https://medium.com/@lquocnam/a-comprehensive-guide-to-angular-observables-bde5542346fc",
              }
            ],
          },
        ],
      },
      {
        organisation: "Node.js Demystified: A Beginner's Guide to Building Server-Side Applications with TypeScript",
        logo: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*rVjj_JOGVJIS8pGEJVrLww.png",
        link: "https://medium.com/@lquocnam/node-js-demystified-a-beginners-guide-to-building-server-side-applications-with-typescript-217591ba71c3",
        positions: [
          {
            title: "Node.js Demystified: A Beginner's Guide to Building Server-Side Applications with TypeScript",
            duration: "Node.js is a robust, event-driven runtime environment designed to build scalable server-side applications. Created by Ryan Dahl in 2009, Node.js has gained immense popularity among developers due to its exceptional efficiency and scalability. By leveraging its event-driven architecture, Node.js can handle a high volume of concurrent requests without blocking the execution flow. This makes it ideal for APIs, real-time, or large-scale applications. As we delve into this beginner's guide, we'll explore the fundamental building blocks of Node.js, its benefits, and how to harness its full potential to build robust and scalable server-side applications.",
            content: [
              {
                text: "Read more",
                link: "https://medium.com/@lquocnam/node-js-demystified-a-beginners-guide-to-building-server-side-applications-with-typescript-217591ba71c3",
              }
            ],
          },
        ],
      }
    ];
  }
}

export default StaticData;
