import {
  tma,
  code88,
  pgmtek
} from "../assets";

import {
  SiJavascript,
  SiBootstrap,
  SiReact,
  SiTailwindcss,
  SiJquery,
  SiVisualstudiocode,
  SiPostman,
  SiGit,
  SiNetlify,
  SiJava,
  SiSpringboot,
  SiSpringsecurity,
  SiAntdesign,
  SiWebpack,
  SiFirebase,
  SiAmazonaws,
  SiPostgresql,
  SiOracle,
  SiApachehive,
  SiDocker,
  SiEclipseide,
  SiIntellijidea,
  SiSubversion,
  SiJenkins,
  SiAmazondynamodb
} from "react-icons/si";

import {
  AiFillGithub,
  AiOutlineGitlab,
} from "react-icons/ai";

import {
  GrServices,
  GrMysql,
  GrHadoop
} from "react-icons/gr";

import {
  DiDatabase,
  DiSpark,
  DiNetbeans
} from "react-icons/di";

import {
  GiDatabase
} from "react-icons/gi";

import { DiCss3, DiMsqlServer, DiRuby } from "react-icons/di";

export const skills = [
  {
    title: "Programming Languages",
    items: [
      {
        id: "pl-1",
        icon: SiJava,
        name: "Java",
      },
      {
        id: "pl-2",
        icon: SiJavascript,
        name: "JavaScript",
      }
    ],
  },
  {
    title: "Frameworks/Libraries",
    items: [
      {
        id: "f-0",
        icon: SiSpringboot,
        name: "SpringBoot",
      },
      {
        id: "f-0-1",
        icon: SiSpringsecurity,
        name: "Spring Security",
      },
      {
        id: "f-0-2",
        icon: GrServices,
        name: "MicroServices",
      },
      {
        id: "f-1",
        icon: SiReact,
        name: "ReactJS",
      },
      {
        id: "f-2",
        icon: SiAntdesign,
        name: "Ant Design",
      },
      {
        id: "f-2-1",
        icon: SiBootstrap,
        name: "Bootstrap",
      },
      {
        id: "f-3",
        icon: SiTailwindcss,
        name: "Tailwind CSS",
      },
      {
        id: "f-4",
        icon: SiJquery,
        name: "jQuery",
      },
      {
        id: "f-5",
        icon: SiWebpack,
        name: "Webpack",
      },
    ],
  },
  {
    title: "Cloud",
    items: [
      {
        id: "c-1",
        icon: SiAmazonaws,
        name: "AWS",
      },
      {
        id: "c-2",
        icon: SiFirebase,
        name: "Firebase",
      }
    ],
  },
  {
    title: "Database",
    items: [
      {
        id: "db-1",
        icon: GrMysql,
        name: "MySQL",
      },
      {
        id: "db-2",
        icon: SiPostgresql,
        name: "Postgresql",
        
      },
      {
        id: "db-3",
        icon: SiOracle,
        name: "Oracle",
      },
      {
        id: "db-4",
        icon: DiDatabase,
        name: "Sysbase",
      }
    ],
  },
  {
    title: "Big Data",
    items: [
      {
        id: "bd-1",
        icon: GrHadoop,
        name: "Hadoop",
      },
      {
        id: "bd-2",
        icon: DiSpark,
        name: "Spark",
        
      },
      {
        id: "bd-3",
        icon: SiApachehive,
        name: "Hive",
      },
      {
        id: "bd-4",
        icon: GiDatabase,
        name: "HBase",
      },
      {
        id: "bd-5",
        icon: SiAmazondynamodb,
        name: "DynamoDB",
      }
    ],
  },
  {
    title: "Tools",
    items: [
      {
        id: "t-1",
        icon: SiDocker,
        name: "Docker",
      },
      {
        id: "t-2",
        icon: SiPostman,
        name: "Postman",
      },
      {
        id: "t-3",
        icon: SiJenkins,
        name: "Jenkins",
      },
      {
        id: "t-4.0",
        icon: SiSubversion,
        name: "SVN",
      },
      {
        id: "t-4.1",
        icon: SiGit,
        name: "Git",
      },
      {
        id: "t-5",
        icon: AiFillGithub,
        name: "GitHub",
      },
      {
        id: "t-6",
        icon: AiOutlineGitlab,
        name: "Gitlab",
      },
      {
        id: "t-7",
        icon: SiNetlify,
        name: "Netlify",
      },
      {
        id: "t-8",
        icon: SiVisualstudiocode,
        name: "VS Code",
      },
      {
        id: "t-9",
        icon: SiEclipseide,
        name: "Eclipse",
      },
      {
        id: "t-10",
        icon: SiIntellijidea,
        name: "Intellij",
      },
      {
        id: "t-11",
        icon: DiNetbeans,
        name: "Netbeans",
      },
    ],
  },
];

export const experiences = [
  {
    organisation: "Insight Global (client Disney)",
    logo: "https://media.licdn.com/dms/image/C560BAQGUNIyRZFaj0g/company-logo_200_200/0/1657049194702/insight_global_logo?e=1724284800&v=beta&t=-AeiY8FGpFUrSgxDYoooY-bVbQ5GZXvE11RgObonFro",
    link: "https://insightglobal.com/",
    positions: [
      {
        title: "Software Engineer III",
        duration: "2023 - now",
        domain: "Supply Chain",
        content: [
          {
            text: "Actively contribute to the enhancement of logistics service applications using Spring Boot, AWS, PL/SQL.",
            link: "",
          },
          {
            text: "Develop and optimize microservices to facilitate seamless communication between Logistics Service applications and third-party systems.",
            link: "",
          },
          {
            text: "Develop Batch applications using Spring Batch for efficient handling of inbound and outbound flows between Logistics Service and third-party applications.",
            link: "",
          },
          {
            text: "Collaborate with teams to troubleshoot errors and enhance existing applications for efficiency.",
            link: "",
          },
          {
            text: "Technologies Used: Java, Spring, IntelliJ, Splunk, Git, AWS, GCP, REST, PL/SQL, MongoDB",
            link: "",
          }
        ],
      },
    ],
  },
  {
    organisation: "Changi Airport Group",
    logo: "https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1445389181/t1rnmahhw02osufcax28.png",
    link: "https://www.changiairport.com",
    positions: [
      {
        title: "Senior Software Engineer",
        duration: "2021 - 2022",
        domain: "Ecommerce",
        content: [
          {
            text: "Developed iShop Changi Marketplace using Java, Spring, and Angular, ensuring seamless operation and user experience.",
            link: "",
          },
          {
            text: "Implemented efficient content exposure to Adobe AEM via REST API, optimizing content management and delivery.",
            link: "",
          },
          {
            text: "Collaborated with Mirakl to enhance overall marketplace functionality by integrating and managing product catalog and merchants via REST API Webservice.",
            link: "",
          },
          {
            text: "Developed Middleware apps using Java, Spring, and Node.JS to communicate with SAP Commerce and small third parties, tailoring integration flows to ECC for smooth, reliable data exchange with merchants.",
            link: "",
          },
          {
            text: "Guided team members and partners, conducted code reviews, and promoted professional growth.",
            link: "",
          },
          {
            text: "Technologies Used: Java, Spring, Angular, IntelliJ, Dynatrace, Kibana, Solr, Git, AWS Lambda, REST, Node.JS, MongoDB, Express, Typescript, Material",
            link: "",
          }
        ],
      },
    ],
  },
  {
    organisation: "Robert Bosch Engineering and Solutions Vietnam",
    logo: "https://isesglobal.com/wp-content/uploads/2023/12/Bosch_symbol_logo_black_red-web.webp",
    link: "https://www.bosch.com/",
    positions: [
      {
        title: "Technical Lead",
        duration: "2017 - 2021",
        domain: "Ecommerce",
        content: [
          {
            text: "Developed Headless Commerce Site with Java, Spring, and Angular on Azure DevOps, ensuring robust e-commerce capabilities.",
            link: "",
          },
          {
            text: "Exposed WCMS data using Drupal via REST API, enhancing content management and delivery.",
            link: "",
          },
          {
            text: "Integrated Azure AD B2B for seamless SSO, improving user authentication and access control.",
            link: "",
          },
          {
            text: "Implemented efficient integrations with SAP ECC through SAP CPI for synchronized master data exchange.",
            link: "",
          },
          {
            text: "Received positive feedback from clients for consistently delivering high-quality work, reflecting a strong commitment to client satisfaction and project success.",
            link: "",
          },
          {
            text: "Technologies Used: Java, Spring, Angular, IntelliJ, JIRA, Confluence, Azure Active Directory",
            link: "",
          }
        ],
      }
    ],
  },
  {
    organisation: "Positive Thinking Company",
    logo: "https://vietnamsoftware.org/cdn/ff/ooEspRUCSVhbgZoXPCsSr1Q3veZgjPeVDgIYaidhcDQ/1661410064/public/2022-08/1603184588248.jpeg",
    link: "https://www.positivethinking.tech",
    positions: [
      {
        title: "Senior Software Engineer",
        duration: "2014 - 2016",
        domain: "Ecommerce",
        content: [
          {
            text: "Implemented a comprehensive, adaptable back-office system to efficiently manage product catalogs, marketing campaigns, and sales funnels, integrating Alipay and UnionPay payment gateways for seamless transactions.",
            link: "",
          },
          {
            text: "Integrated new system with existing warehouse management program provided by Baozun, optimizing order fulfillment process.",
            link: "",
          },
          {
            text: "Effectively managed back-end content using Flexible Search, ImpEx, and Groovy scripts for products and orders, ensuring accurate data management and streamlined operations. Customized content approval workflows, enhancing collaboration and expediting content publishing.",
            link: "",
          },
          {
            text: "Redesigned the user experience of a public e-commerce site, improving usability, navigation, and overall customer satisfaction.",
            link: "",
          },
          {
            text: "Implemented a user-friendly portal to provide customers with a seamless, personalized experience.",
            link: "",
          },
          {
            text: "Designed and produced marketing materials that support ongoing campaigns, ensuring consistent and compelling brand messaging.",
            link: "",
          },
          {
            text: "Implemented the integration of Hybris with Supply Chain Oracle Retail to streamline order processing and inventory management, resulting in improved efficiency and reduced fulfillment times.",
            link: "",
          },
          {
            text: "Offered application-level SLA support, ensuring the availability, performance, and reliability of the SAP Commerce application.",
            link: "",
          },
          {
            text: "Promoted to Performance Team, successfully resolving critical performance and production issues.",
            link: "",
          },
          {
            text: "Technologies Used: Core Java, Spring, IntelliJ, JIRA, Confluence, SQL",
            link: "",
          }
        ],
      }
    ],
  },
  
];
